// src/Screens/Products.js
import React, { useState } from 'react';
import styles from './Products.module.css';
import financialPortfolioLogo from '../assets/poly.svg';
import fp_preview_1 from '../assets/fp_1.png';
import fp_preview_2 from '../assets/fp_2.png';
import fp_preview_3 from '../assets/fp_3.png';
import fp_preview_4 from '../assets/fp_4.png';

export default function Products() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const products = [
        {
            name: "financial-portfolio.io",
            logo: financialPortfolioLogo,
            description: "Financial-portfolio.io is your intelligent guide to financial research. Enter a ticker symbol, and it immediately pulls key financial metrics, relevant news, and offers deeper analysis on the market trends impacting the company.",
            url: "https://www.financial-portfolio.io/",
            previews: [fp_preview_1, fp_preview_2, fp_preview_3, fp_preview_4],
        },
    ];

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % products[0].previews.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + products[0].previews.length) % products[0].previews.length);
    };

    return (
        <div className="dark-bg-page">
            <div className={styles.mainContainer}>
                <div className={styles.productSection}>
                    <div className={styles.productInfo}>
                        <img src={products[0].logo} alt={`${products[0].name} logo`} className={styles.productLogo} />
                        <h1>{products[0].name}</h1>
                        <p>{products[0].description}</p>
                        <a href={products[0].url} className={styles.exploreButton} target="_blank" rel="noopener noreferrer">
                            Explore Our Product
                        </a>
                    </div>
                    
                    <div className={styles.previewSection}>
                        <div className={styles.carouselContainer}>
                            <button onClick={prevSlide} className={styles.carouselButton}>&lt;</button>
                            <img 
                                src={products[0].previews[currentSlide]} 
                                alt={`${products[0].name} preview`} 
                                className={styles.previewImage} 
                            />
                            <button onClick={nextSlide} className={styles.carouselButton}>&gt;</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}