// src/Screens/Home.js
import React from 'react';
import './Home.css';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SEO from '../Components/SEO';

function Home() {
  return (
    <>
      <SEO 
        title="Info-com.io: AI and Blockchain-Powered Search Engines"
        description="Explore immutable, AI-driven financial insights. Financial-Portfolio.io offers historical snapshots and investment analysis, leveraging blockchain for trustworthy and timeless data."
        keywords="financial insights, blockchain, AI-driven analysis, historical data, investment platform"
        canonical="https://financial-portfolio.io/"
      />
      <main className="main_div_home">
        <Container className="content-container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="headline">From Search to Exploration Engines</h1>
            <h2 className="subheadline">Automated Payments for Fair Content Sourcing</h2>
            <Link to="/products" className="custom-btn">Explore Our Technology</Link>
          </motion.div>
        </Container>
      </main>
    </>
  );
}

export default Home;